/* eslint-disable no-unused-vars */

import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

Fancybox.bind('[data-fancybox="single-gallery"]', {
  // Your custom options
});

Fancybox.bind('[data-fancybox="photos-gallery"]', {
  // Your custom options
});

/* eslint-enable no-unused-vars */