// pas de redirection au 1er click si sous-menu à faire apparaitre
var navlinksWithChildsAll = document.querySelectorAll('.menu-item-has-children > a');
var navlinksWithChildsLevel1 = document.querySelectorAll('.menu-pratiquer > .menu-item-has-children > a');
var navlinksWithChildsLevel2 = document.querySelectorAll('.sub-menu .menu-item-has-children > a');

navlinksWithChildsLevel1.forEach( navlink1 => {
  navlink1.addEventListener('click', (e) => {
    // si sous-menu pas ouvert, pas de redirection au clic pour permettre l'ouverture
    if( !navlink1.classList.contains('opened') ) {
      e.preventDefault();
    }

    // on ferme tous les sous-menus éventuellement ouvert
    navlinksWithChildsAll.forEach( navlinkall => {
      navlinkall.classList.remove('opened');
    });

    // on ouvre ou on rend la redirection possible au clic sur l'élément courant
    navlink1.classList.toggle('opened');

    // gestion de la visibilité de la photo de base du menu
    navlinksWithChildsLevel1.forEach( navlink1 => {
      navlink1.parentElement.classList.remove('photo-visible');
    });

    if( navlink1.classList.contains('opened') ) {
      navlink1.parentElement.classList.add('photo-visible');
    }
  });
});

// idem pour niveaux 2
navlinksWithChildsLevel2.forEach( navlink2 => {
  navlink2.addEventListener('click', (e) => {
    if( !navlink2.classList.contains('opened') ) {
      e.preventDefault();
    }
    navlinksWithChildsLevel2.forEach( navlink2 => {
      navlink2.classList.remove('opened');
    });

    navlink2.classList.toggle('opened');
  });
});

// ajout d'une classe spécifique au conteneur global à chaque niveaux de navigation en cours
var navContent = document.querySelector('.navbar-pratiquer .navbar-content');

navlinksWithChildsLevel1.forEach( navlink1 => {
  navlink1.addEventListener('click', () => {
    navContent.classList.add('open-level1');
    navContent.classList.remove('open-level2');
  });
});

navlinksWithChildsLevel2.forEach( navlink2 => {
  navlink2.addEventListener('click', () => {
    navContent.classList.add('open-level2');
  });
});

var elementPratiquer = document.querySelector('#navbar-pratiquer');
elementPratiquer.addEventListener('hide.bs.collapse', () => {
  navContent.classList.remove('open-level1');
  navContent.classList.remove('open-level2');
});
