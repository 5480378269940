import {Collapse} from 'bootstrap';

// collapse le menu ouvert si on ouvre le 2e
var hamburgerSF = document.querySelector('.hamburger-sf');
var hamburgerPratiquer = document.querySelector('.hamburger-pratiquer');
var elementSF = document.querySelector('#navbar-sf');
var collapseSF = new Collapse(elementSF, {toggle: false});
var elementPratiquer = document.querySelector('#navbar-pratiquer');
var collapsePratiquer = new Collapse(elementPratiquer, {toggle: false});
var body = document.body;
var closeSF = document.querySelector('.close-navbar-sf');

hamburgerSF.addEventListener('click', () => {
  hamburgerSF.classList.toggle('is-active');
  body.classList.toggle('nav-sf-opened');
  collapseSF.toggle();
});

closeSF.addEventListener('click', () => {
  hamburgerSF.classList.toggle('is-active');
  body.classList.toggle('nav-sf-opened');
  collapseSF.toggle();
});

hamburgerPratiquer.addEventListener('click', () => {
  hamburgerPratiquer.classList.toggle('is-active');
  body.classList.toggle('nav-pratiquer-opened');
  collapsePratiquer.toggle();
});

elementSF.addEventListener('show.bs.collapse', () => {
  collapsePratiquer.hide();
  hamburgerPratiquer.classList.remove('is-active');
  body.classList.add('nav-sf-opened');
  body.classList.remove('nav-pratiquer-opened');
});

elementPratiquer.addEventListener('show.bs.collapse', () => {
  collapseSF.hide();
  hamburgerSF.classList.remove('is-active');
  body.classList.add('nav-pratiquer-opened');
  body.classList.remove('nav-sf-opened');
});

// ferme tous les sous-niveaux du menu "pratiquer" quand on le ferme
// et masque l'éventuelle photo du menu
var navlinksWithChildsAll = document.querySelectorAll('.menu-item-has-children > a');

elementPratiquer.addEventListener('hide.bs.collapse', () => {
  navlinksWithChildsAll.forEach( navlinkall => {
    navlinkall.classList.remove('opened');
    navlinkall.parentElement.classList.remove('photo-visible');
  });
});