// FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
function autoPlayYouTubeModal(){
  var trigger = $("body").find('[data-bs-target="#modalVideo"]');
  trigger.click(function() {
    var modalVideo = $(this).data( "bs-target" ),
    videoSRC = $(this).attr( "data-video" );
    $(modalVideo+' iframe').attr('src', videoSRC);
    $(modalVideo).on('hidden.bs.modal', function () {
      $(modalVideo+' iframe').removeAttr('src');
    });
  });
}

$(document).ready(function(){
  autoPlayYouTubeModal();
});
