/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider navbar */
var navbar_slide = new Swiper('.swiper-navbar', {
  slidesPerView: 'auto',
  centeredSlides: false,
});

/* Slider partenaires */
var partenaires_slide = new Swiper('.swiper-partenaires', {
  slidesPerView: 3,
  spaceBetween: 20,
  centeredSlides: false,
  loop: false,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    // when window width is >= 560px
    560: {
      slidesPerView: 4,
    },
    991: {
      loop: true,
      slidesPerView: 5,
    },
    1200: {
      loop: true,
      slidesPerView: 6,
      spaceBetween: 30,
    },
    1400: {
      loop: true,
      slidesPerView: 7,
      spaceBetween: 40,
    },
  },
});

/* Slider home hero */
var homehero_content_swiper = new Swiper('.homehero-content-swiper', {
  slidesPerView: 1,
  loop: false,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
});

var homehero_slide = new Swiper('.homehero-swiper', {
  slidesPerView: 1,
  loop: false,
  thumbs: {
    swiper: homehero_content_swiper,
  },
});

homehero_content_swiper.on("slideChange", () => {
  homehero_slide.slideTo(homehero_content_swiper.activeIndex);
});

/* Slider home pratiques */
var homepratiques_slide = new Swiper('.homepratiques-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

/* Slider home évènements */
var homeevts_slide = new Swiper('.evts-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 12,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
    },
    1200: {
      spaceBetween: 30,
    },
  },
});

/* Slider home videos */
var homevideos_slide = new Swiper('.videos-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 12,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
    },
    1200: {
      spaceBetween: 30,
    },
  },
});

/* Slider home gallery */
var homegallery_thumbs_slide = new Swiper('.homegallery-thumbs-swiper', {
  slidesPerView: 'auto',
  loop: false,
  centeredSlides: false,
  spaceBetween: 20,
  breakpoints: {
    1200: {
      spaceBetween: 30,
    },
  },
});

var homegallery_swiper = new Swiper('.homegallery-swiper', {
  slidesPerView: 1,
  loop: false,
  thumbs: {
    swiper: homegallery_thumbs_slide,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

homegallery_swiper.on("slideChange", () => {
  homegallery_thumbs_slide.slideTo(homegallery_swiper.activeIndex);
});

/* Slider single gallery */
var singlegallery_slide = new Swiper('.gallery-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 12,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
    },
    1200: {
      spaceBetween: 30,
    },
  },
});

/* Slider pratiques secondaires */
var pratiquessecondaires_slide = new Swiper('.ps-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

/* Sliders pratiques majeures */
/* 3 sliders synchronisés */
var pm_title_slide = new Swiper('.pm-title-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
});

var pm_img_slide = new Swiper('.pm-img-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  direction: "vertical",
  thumbs: {
    swiper: pm_title_slide,
  },
});

var pm_content_slide = new Swiper('.pm-content-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
});

pm_img_slide.controller.control = [pm_title_slide, pm_content_slide];
pm_content_slide.controller.control = [pm_img_slide, pm_title_slide];

/* Slider block slider-photos */
var photos_slide = new Swiper('.photos-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 12,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
    },
    1200: {
      spaceBetween: 30,
    },
  },
});

/* Slider block actus */
var actus_slide = new Swiper('.actus-swiper', {
  slidesPerView: 'auto',
  centeredSlides: false,
  spaceBetween: 12,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
    },
    1200: {
      spaceBetween: 30,
    },
  },
});

/* eslint-enable no-unused-vars */
