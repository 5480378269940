const $document = $(document);
const $body = $('body');

$document.on("submit", ".js-form-filter", function (event) {
	const $form = $(this);

	let categoryfilter = '';
  let orderfilter = '';

	const $selectedCat = $form.find("#categoryfilter");
  const $selectedOrder = $form.find("#orderfilter");

	if ($selectedCat.val()) {
		categoryfilter = $selectedCat.val();
	}

  if ($selectedOrder.val()) {
		orderfilter = $selectedOrder.val();
	}

	event.preventDefault();

	$.ajax({
		url: window.location.href,
		method: "GET",
		data: {
			category_filter: categoryfilter,
      order_filter: orderfilter,
		},
		beforeSend: function () {
			$body.addClass("is-loading");
		},
		success: function (response) {
			if ($(response).find(".posts-grid").length) {
				const newEntries = $(response).find(".posts-grid").html();
				$(".posts-grid").html(newEntries);
			}

      const $newPagination = $(response).find(".js-load-more");
			if ($newPagination.length) {
				$(".js-load-more")
					.attr("href", $newPagination.attr("href"))
					.show();
			} else {
				$(".js-load-more").hide();
			}
		},
		complete: function () {
			$body.removeClass("is-loading");
		},
	});
});

$document.on("change", ".js-select", function () {
	$('.js-form-filter').trigger('submit');
});
