const $document = $(document);

$document.on("click", ".js-load-more", function (event) {
	event.preventDefault();

	$.ajax({
		url: $(this).attr("href"),
		success: function (response) {
			const newEntries = $(response).find(".posts-grid").html();
			$(".posts-grid").append(newEntries);

			const $newPagination = $(response).find(".js-load-more");
			if ($newPagination.length) {
				$(".js-load-more")
					.attr("href", $newPagination.attr("href"))
					.show();
			} else {
				$(".js-load-more").hide();
			}
		},
	});
});