// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

import './modules/hamburgers';
import './modules/menu-pratiquer';
import './modules/sliders';
import './modules/modal-yt-video';
import './modules/posts-filters';
import './modules/evenements-filters';
import './modules/annonces-filters';
import './modules/formations-filters';
import './modules/load-more-filters';
import './modules/fancybox';
import './modules/annonce-form';
